"use strict";

export default class RatingComment {
    constructor() {
        let it = this;

        it.$wrap = $('.raiting__review');
    }

    init() {
        let it = this;

        it.$button = it.$wrap.find('.raiting__review__button_ajax');
        it.$list = it.$wrap.find('.raiting__review__list');
        it.$id = $('main').attr("data-recipe-id");
        it.$limit = 7;
        it.$button.on('click', function () {
            if (it.$button.attr('selected')) {
                return false;
            }
            it.$last_comment_id = it.$wrap.find('.raiting__review__list>.raiting__review__list_item').eq(-1).attr("data-comment-id");
            it.$button.attr('selected', 'selected');
            it.ajax(it.$id, it.$last_comment_id, it.$limit);
        });
    }

    /**
     * Ajax
     */
    ajax($id, $last_comment_id, $limit) {
        let it = this;
        let type = 'GET';
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/api/recipeCommentsAjax/' + $id + '/' + $last_comment_id + '/' + $limit + '/',
                type,
                success: data => {
                    if (typeof data === 'string') {
                        data = JSON.parse(data);
                    }

                    if (data.data.length > 0) {
                        it.renderComment(data.data);
                        it.$button.removeAttr('selected');
                    }

                    if (data.data.length < 7) {
                        it.$button.remove();
                    }
                },
                error: err => {
                }
            });
        });
    }

    formatDate(date) {
        let timeStump = new Date(date);
        return timeStump.getDate() + '.' + ("0" + (timeStump.getMonth() + 1)).slice(-2) + '.' + timeStump.getFullYear();
    }

    renderComment(data) {
        let it = this;
        for (let item in data) {
            let author = data[item].author ? data[item].author : 'Гость';
            it.$list.append('<div class="raiting__review__list_item" data-comment-id="' + data[item].id + '"><div><div class="raiting__review__list_item_name">' + author + '</div><div class="raiting__review__list_item_date">' + it.formatDate(data[item].created_at) + '</div></div><div class="raiting__review__list_item_rate"><div class="el-rate el-comment__rate user-rate_' + data[item].rating + '"><div class="el-rate__stars"><svg><use xlink:href="#svg-rate-star"></use></svg><svg><use xlink:href="#svg-rate-star"></use></svg><svg><use xlink:href="#svg-rate-star"></use></svg><svg><use xlink:href="#svg-rate-star"></use></svg><svg><use xlink:href="#svg-rate-star"></use></svg></div></div></div><div class="raiting__review__list_item_comment"><span>' + data[item].text + '</span></div></div>');
        }
    }
}
