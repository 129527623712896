// "use strict";
import Tooltip from '../modules/tooltip';
const tooltip = new Tooltip();

import Modal from '../modules/modal';
const modal = new Modal();

export default class Registration {

  constructor () {
    let it = this;

    if(!$(".registration__form").length) return;

    it.$form = $(".registration__form");
    it.$submit_btn = it.$form.find("button[type=submit]");
    it.$city_id_input = it.$form.find("input[name=city_id]");
    it.$phone_input = it.$form.find("input[name=phone]");

    it.dadata_key = "ce4224790db3e559a5664421ebc9cfcfa6f8db35";
    it.dadata_results = [];

    it.errorMessages = {
      "first_name": {
          required: "Введите имя",
          cyrillic: "Только кириллица",
      },
      "last_name": "Введите фамилию",
      "city": "Выберите город",
      "email": {
          required: "Введите E-mail",
          email: "Некорректный E-mail",
      },
      "phone": {
          required: "Введите телефон",
          number: "Допускаются только цифры 0-9",
          minlength: "Минимум 8 символов",
      },
      "password": {
        minlength: "Минимум 6 символов",
      },
      "password-repeat": {
        required: "Подтвердите пароль",
        equalTo: "Пароли не совпадают",
      }
    }

    it.initForm();
  }


  // Инитим форму ---------

  initForm() {
    let it = this;

    it.$form[0].reset();
    it.$submit_btn.attr("disabled", "");

    it.$phone_input = it.$form.find("input[name=phone]");
    it.$phone_input.mask("(999) 999-99-99");

    const $city_select = it.$form.find("select[name=city]");

    // Инитим селект городов ---------
    $city_select.select2({
      placeholder: "Выберите город",
      dropdownParent: $('._registration'),
      language: {
        searching: function () {
          return "Поиск..";
        },
        noResults: function () {
          return "Нет результатов";
        },
        errorLoading: function () {
          return "Ошибка загрузки";
        },
      },
      // Юзаем встроенный (слава плагину) метод подгрузки опций аяксом ---------
      ajax: {
        url: '/api/cities/',
        type: "GET",
        contentType: "application/json",
        data: function (params) {
          return {
            q: params.term,
          }
        },
        // Обрабатываем результат поиска ---------
        processResults: function (data) {
          var formatted_data = []; // то что уйдёт в селект для рендеринга опций
          it.dadata_results = []; // сохраняем результат аякса на потом

          data.cities.forEach((item, index) => {
            it.dadata_results.push(item);

            formatted_data.push({
              id: item.id,
              text: item.name,
            });
          })

          return {
            results: formatted_data,
          };
        }
      },
    });


    // Обработчик выбора города юзером
    $city_select.on("select2:select", function(event) {
      $city_select.parent().removeClass("has-error");
      validator.element($city_select);

      // Берём id выбранной юзером опции (там записано название города в виде "г Москва" и т.д)
      const selected_place = event.params.data.id;
      console.log(selected_place)

      // Ищем в сохранённом ранее результате аякса id города и региона по id
      it.dadata_results.forEach((item, index) => {
        if(item.id === selected_place) {
          // Подставляем в инпут
          it.$city_id_input.val(item.id);
        }
      })
    })


    // По открытию селекта вешаем ему скроллбар
    const scrollbar = `
                <div class="baron__track">
                    <div class="select__bar baron__bar"></div>
                </div>`

    it.scroller = null;

    $city_select.on("select2:open", function() {
      setTimeout(() => {
        if(!it.scroller) {
            $('.select2-results').append(scrollbar);
            it.scroller = $('.select2-results__options').baron({
                root: $('.select2-results'),
                bar: $('.select__bar'),
            });
        } else {
            it.scroller.update();
        }

        if($city_select.parent().hasClass("has-error")) {
            $('.select2-dropdown').addClass("error");
        } else {
            $('.select2-dropdown').removeClass("error");
        }
      }, 0);
    });



    it.$form.submit(function(event) {
      event.preventDefault();
    });

    // Валидация формы
    let validator = it.$form.validate({
        errorElement: "label",
        focusInvalid: false,
        highlight: function(element) {
          $(element).addClass("error");
          $(element).parent().addClass("has-error").removeClass("valid");
          it.$submit_btn.attr("disabled", "");
        },
        unhighlight: function(element) {
          $(element).removeClass("error");
          $(element).parent().addClass("valid").removeClass("has-error");
          it.checkFormValidation();
        },
        submitHandler: function() {
          it.handleFormSubmit();
        },
        onkeyup: function( element, event ) {
            if ( event.which === 9 && this.elementValue(element) === "" ) {
                return;
            } else {
                this.element(element);
            }
        },
        rules: {
            "first_name": {required: true, cyrillic: true},
            // "last_name": {required: true},
            "city": {required: true},
            "email": {required: true, email: true},
            "phone": {required: true}, //, number: true, minlength: 8
            // "password": {required: true, minlength: 6},
            // "password-repeat": {equalTo: it.$form.find("input[name=password]"), }
            "registration-agreement": {required: true}
        },
        messages: it.errorMessages,
    });

    it.$form.on("file-error", function() {
        it.$form.addClass("file-error");
        it.checkFormValidation();
    })

    it.$form.on("file-success", function() {
        it.$form.removeClass("file-error");
        it.checkFormValidation();
    })
  }

  checkFormValidation() {
    let it = this;

    if(it.$form.validate().checkForm() && !it.$form.hasClass("file-error")) {
      it.$submit_btn.removeAttr("disabled");
    } else {
      it.$submit_btn.attr("disabled", "");
    }
  }

  handleFormSubmit() {
    let it = this,
        type = it.$form.attr('method'),
        url = it.$form.attr('action');

    const $city_select = it.$form.find("select[name=city]");
    // Телефон надо отдать на сервер в другом виде
    let unformatted_phone = it.$phone_input.val();
    let formatted_phone = "7 " + unformatted_phone;

    it.$phone_input.val(formatted_phone);
    let data = new FormData(it.$form[0]);
    it.$phone_input.val(unformatted_phone);

    it.$submit_btn.addClass("loading").attr("disabled", "").find("p").html("Подождите");

    setTimeout(() => {
        $.ajax({
            url,
            type,
            data,
            processData: false,
            contentType: false,
            success: data => {
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }

                if(data.status === true) {
                    it.$form[0].reset();
                    $city_select.trigger("change");
                    modal.open("registered");

                    // отправка в GTM
                    if (dataLayer && typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'SignUpSuccess',
                            'eventCategory': 'SendForm',
                            'eventAction': 'SignUpSuccess'
                        });
                    }

                    console.log(data);

                    if (data.phoneSendCode.original.d.Data.data.Result) {
                      $('#auth-uid').val(data.uid);
                    } else {
                      console.log('phoneSendCode: ', data.phoneSendCode.original.d.Data.data.Code);
                    }
                } else {
                    tooltip.show('Ошибка. Информация в консоли ', 0);
                }
            },
            error: err => {

                // Очкорылые программисты первосайта сделали какую-то хуету, приходится костылями дрочить
                if ( err.responseJSON.d.Data.data.Code ) {
                    $('#phone-error').text(err.responseJSON.d.Data.data.Code).show();
                }
                console.log('Ошибка отправки формы', err);
            },
            complete: data => {
                it.$submit_btn.removeClass("loading").removeAttr("disabled").find("p").html("Зарегистрироваться");
                console.log(data);
            }
        });
    }, 1500)
  }
}
