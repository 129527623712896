/*
Здесь перечислены основные формы, для которых не было смысла создавать отдельный модуль, кек
 */

"use strict";

import Fingerprint2 from 'fingerprintjs2';

import Tooltip from '../modules/tooltip';
const tooltip = new Tooltip();

import Modal from '../modules/modal';
const modal = new Modal();

import Assay from '../helpers/assay';
const assay = new Assay();

import Registration from '../modules/registration';
const registration = new Registration();

import Authorization from '../modules/authorization';
const authorization = new Authorization();

import PasswordRecovery from '../modules/password-recovery';
const passwordRecovery = new PasswordRecovery();

export default class MainForms {
    constructor () {
        let it = this;

        it.$subscribe = $('.subscribe');
        it.$feedback = $('.feedback-form');
        it.$review = $('.review-form');
        it.$comment = $('.comment-form');
        it.$review_submit = it.$review.find('button[type=submit]');
        it.$comment_submit = it.$comment.find('button[type=submit]');

        it.$feedback_captcha = $("#feedback-captcha");
        it.$feedback_submit = it.$feedback.find('button[type=submit]');

        it.errorMessages = {
            "author": {
                required: "Введите имя",
                cyrillic: "Только кириллица",
            },
            "subject": "Выберите тему",
            "email": {
                required: "Введите E-mail",
                email: "Некорректный E-mail",
            },
            "phone": {
                required: "Введите телефон",
                number: "Допускаются только цифры 0-9",
                minlength: "Минимум 8 символов",
            },
            "text": {
                required: "Введите сообщение",
                minlength: "Минимум 20 символов",
            },
            "rating": {
                required: "Поставьте оценку",
            },
            "agreement": {
                required: "",
            }
        };

        setTimeout(() => {
            Fingerprint2.get(function (components) {
                let values = components.map(function (component) { return component.value });
                let result = Fingerprint2.x64hash128(values.join(''), 31);

                if($(".registration__form").length) {
                    $(".registration__form").find("input[name=fingerprint]").val(result)
                }

                if($(".authorization__form").length) {
                    $(".authorization__form").find("input[name=fingerprint]").val(result)
                }

                if($(".profile__form").length) {
                    $(".profile__form").find("input[name=fingerprint]").val(result)
                }

                if($(".password-recovery__form").length) {
                    $(".password-recovery__form").find("input[name=fingerprint]").val(result)
                }
            })
        }, 500);
    }

    /**
     * Init
     */
    init () {
        let it = this;

        if (it.$subscribe.length) {
            it.$subscribe.each((i, form) => {
                it.subscribe($(form));
            });
        }

        if (it.$feedback.length) {
            it.$feedback.each((i, form) => {
                it.feedback($(form));
            });
        }

        if (it.$review.length) {
            it.$review.each((i, form) => {
                it.review($(form));
            });
        }

        if (it.$comment.length) {
            it.$comment.each((i, form) => {
                it.comment($(form));
            });
        }

        window.handleFeedbackCaptchaSuccess = it.handleFeedbackCaptchaSuccess;
        window.handleFeedbackCaptchaExpiration = it.handleFeedbackCaptchaExpiration;
        window.checkFeedbackValidation = it.checkFeedbackValidation;

        window.handleReviewCaptchaSuccess = it.handleReviewCaptchaSuccess;
        window.handleReviewCaptchaExpiration = it.handleReviewCaptchaExpiration;
        window.checkReviewValidation= it.checkReviewValidation;
    }



    /**
     * Feedback
     */

    feedback ($form) {
        let it = this,
            $email = $form.find('input[type=email]'),
            $theme = $form.find('select[name=subject]'),
            $name = $form.find('input[name=author]'),
            $phone = $form.find('input[name=phone]'),
            $text = $form.find('textarea'),
            $file = $form.find('input[name=file]'),
            $agreement = $form.find('input[name=feedback-agreement]'),
            $submit = $form.find('button[type=submit]');

        $form[0].reset();
        $submit.attr("disabled", "");

        $theme.select2({
            dropdownParent: $('._feedback'),
            minimumResultsForSearch: Infinity,
            placeholder: "Выберите тему",
        });

        const scrollbar = `
                <div class="baron__track">
                    <div class="select__bar baron__bar"></div>
                </div>`

        it.scroller = null;

        $theme.on("select2:open", function() { //!$(".select2-dropdown .baron__track").length
            setTimeout(() => {
                if(!it.scroller) {
                    $('.select2-results').append(scrollbar);
                    it.scroller = $('.select2-results__options').baron({
                        root: $('.select2-results'),
                        bar: $('.select__bar'),
                    });
                } else {
                    it.scroller.update();
                }

                if($theme.parent().hasClass("has-error")) {
                    $('.select2-dropdown').addClass("error");
                } else {
                    $('.select2-dropdown').removeClass("error");
                }
            }, 0)
        })

        $form.on("file-error", function() {
            $form.addClass("file-error");
            it.checkFeedbackValidation();
        })

        $form.on("file-success", function() {
            $form.removeClass("file-error");
            it.checkFeedbackValidation();
        })

        $form.submit(function(event) {
            event.preventDefault();
        })

        let validator = $form.validate({
            errorElement: "label",
            focusInvalid: false,
            highlight: function(element) {
              $(element).addClass("error");
              $(element).parent().addClass("has-error").removeClass("valid");
              $submit.attr("disabled", "");
            },
            unhighlight: function(element) {
              $(element).removeClass("error");
              $(element).parent().addClass("valid").removeClass("has-error");
              it.checkFeedbackValidation();
            },
            submitHandler: function() {
                it.handleFeedbackSubmit($form);
            },
            onkeyup: function( element, event ) {
                if ( event.which === 9 && this.elementValue(element) === "" ) {
                    return;
                } else {
                    this.element(element);
                }
            },
            rules: {
                "author": {required: true, cyrillic: true},
                "subject": {required: true},
                "email": {required: true, email: true},
                "phone": {required: true, number: true, minlength: 8},
                "text": {required: true, minlength: 20},
                "feedback-agreement": {required: true}
            },
            messages: it.errorMessages,
        });

        $theme.on("select2:select", function() {
            $theme.parent().removeClass("has-error");
            validator.element($theme)
        })
    }

    handleFeedbackSubmit($form) {
        let it = this,
            type = $form.attr('method'),
            url = $form.attr('action'),
            data = data = new FormData($form[0]);

        it.$feedback_submit.addClass("loading").attr("disabled", "").find("p").html("Письмо отправляется");

        setTimeout(() => {
            $.ajax({
                url,
                type,
                data,
                processData: false,
                contentType: false,
                success: data => {
                    if (typeof data === 'string') {
                        data = JSON.parse(data);
                    }
                    if(data.success) {
                        modal.open('feedbacked');
                        $form.find("textarea").val("");
                        $('.form__file-item').length ? $('.form__file-item').remove() : '';
                        $('input[name=file]').val('');
                        $('.feedback-form').find('select').val(null).trigger('change');
                        $('.feedback-form').find('input').val(null);
                        $('.feedback-form').find('textarea').val(null);
                        $('.feedback-form').find('.form__file-item-delete').click();
                        //window.registration_captcha.reset(); // тут ошибка скорее всего слетела версия в сборщике
                    } else {
                        tooltip.show('Ошибка. Информация в консоли ', 0);
                        console.log(data);
                    }
                },
                error: err => {
                    tooltip.show('Ошибка. Информация в консоли ', 0);
                    console.log('Ошибка отправки формы', err);
                },
                complete: data => {
                    it.$feedback_submit.removeClass("loading").removeAttr("disabled").find("p").html("Отправить сообщение");
                }
            });
        }, 1500)
    }

    checkFeedbackValidation() {
        const $form = $(".feedback-form");
        const $captcha = $("#feedback-captcha");
        const $submit = $form.find('button[type=submit]');

        if($form.validate().checkForm() && $captcha.hasClass("success") && !$form.hasClass("file-error")) {
            $submit.removeAttr("disabled");
        } else {
            $submit.attr("disabled", "");
        }
    }

    handleFeedbackCaptchaSuccess() {
        return new Promise((resolve, reject) => {
            try {
              $("#feedback-captcha").addClass("success");
              checkFeedbackValidation();

              resolve();
            } catch (err) {
              reject(err.message);
            }
        });
    }

    handleFeedbackCaptchaExpiration() {
      return new Promise((resolve, reject) => {
        try {
          $("#feedback-captcha").removeClass("success");
          checkFeedbackValidation();
        } catch(err) {
          reject(err.message);
        }
      });
    }







    /**
     * Review
     */
    review($form) {
        let it = this,
            type = $form.attr('method'),
            url = $form.attr('action'),
            $email = $form.find('input[type=email]'),
            $name = $form.find('input[name=author]'),
            $phone = $form.find('input[name=phone]'),
            $text = $form.find('textarea'),
            $res = $form.find('.feedback-form__res'),
            $agreement = $form.find('input[name=review-agreement]'),
            $submit = $form.find('button[type=submit]');


        $form[0].reset();
        $submit.attr("disabled", "");

        $form.submit(function(event) {
            event.preventDefault();
        });

        let validator = $form.validate({
            errorElement: "label",
            focusInvalid: false,
            highlight: function(element) {
              $(element).addClass("error");
              $(element).parent().addClass("has-error").removeClass("valid");
              $submit.attr("disabled", "");
            },
            unhighlight: function(element) {
              $(element).removeClass("error");
              $(element).parent().addClass("valid").removeClass("has-error");
              it.checkReviewValidation();
            },
            submitHandler: function() {
                it.handleReviewSubmit($form);
            },
            onkeyup: function( element, event ) {
                if ( event.which === 9 && this.elementValue(element) === "" ) {
                    return;
                } else {
                    this.element(element);
                }
            },
            rules: {
                "author": {required: true},
                "email": {required: true, email: true},
                "phone": {required: true, number: true, minlength: 8},
                "text": {required: true, minlength: 20},
                "review-agreement": {required: true}
            },
            messages: it.errorMessages,
        });

        // $form.validate().checkForm();
    }


    checkReviewValidation() {
        const $form = $(".review-form");
        const $captcha = $("#review-captcha");
        const $submit = $form.find('button[type=submit]');

        if($form.validate().checkForm() && $captcha.hasClass("success")) {
            $submit.removeAttr("disabled");
        } else {
            $submit.attr("disabled", "");
        }
    }


    handleReviewSubmit($form) {
        let it = this,
            type = $form.attr('method'),
            url = $form.attr('action'),
            data = new FormData($form[0]);

        it.$review_submit.addClass("loading").attr("disabled", "").find("p").html("Письмо отправляется");

        setTimeout(() => {
            $.ajax({
                url,
                type,
                data,
                processData: false,
                contentType: false,
                success: data => {
                    if (typeof data === 'string') {
                        data = JSON.parse(data);
                    }

                    if ( data.success ) {
                        modal.open('reviewed');
                        $form.find("textarea").val("");
                    } else {
                        tooltip.show('Ошибка. Информация в консоли ', 0);
                        console.log(data);
                    }
                },
                error: err => {
                    tooltip.show('Ошибка. Информация в консоли ', 0);
                    console.log(err);
                },
                complete: data => {
                    it.$review_submit.removeClass("loading").removeAttr("disabled").find("p").html("Отправить сообщение");
                }
            });
        }, 1500)
    }

    handleReviewCaptchaSuccess() {
        return new Promise((resolve, reject) => {
            try {
                $("#review-captcha").addClass("success");
                checkReviewValidation();

                resolve();
            } catch (err) {
                reject(err.message);
            }
        });
    }

    handleReviewCaptchaExpiration() {
        return new Promise((resolve, reject) => {
            try {
                $("#review-captcha").removeClass("success");
                checkReviewValidation();
            } catch(err) {
                reject(err.message);
            }
        });
    }







    /**
     * Subscribe for Recipes
     */
    subscribe ($form) {
        let it = this,
            type = $form.attr('method'),
            url = $form.attr('action'),
            $email = $form.find('input[type=email]'),
            $label = $form.find('label'),
            $submit = $form.find('input[type=submit]'),
            data = {},
            sending = false,
            ajax = () => {
                return new Promise (resolve => {
                    $.ajax({
                        url, type, data,
                        success: data => {
                            if (typeof data === 'string') {
                                data = JSON.parse(data);
                            }

                            if ( data.success ) {
                                // отправка в GTM
                                if (dataLayer && typeof dataLayer !== "undefined") {
                                    dataLayer.push({
                                        'event': 'SubscrSuccess',
                                        'eventCategory': 'SendForm',
                                        'eventAction': 'SubscrSuccess'
                                    });
                                }
                                modal.open('subscribed');
                                resolve();
                            } else {
                                if (data.message) {
                                    if (data.message.message) {
                                        $label.text(data.message.message);
                                        $email.parent().addClass('_error');
                                    } else {
                                        $label.text(data.message);
                                        $email.parent().addClass('_error');
                                    }
                                } else {
                                    $label.text('Произошла ошибка');
                                    $email.parent().addClass('_error');
                                }
                            }
                        },
                        error: err => {
                            console.log(err);

                            if (err.status == 400) {
                                if (err.responseJSON.message) {
                                    if (err.responseJSON.message.message) {
                                        $label.text(err.responseJSON.message.message);
                                    } else {
                                        $label.text('Произошла ошибка');
                                    }
                                } else {
                                    $label.text('Произошла ошибка');
                                }
                                $email.parent().addClass('_error');
                            }
                        }
                    });
                });
            };



        $submit.on('click', function (e) {
            if (sending) return false;
            e.preventDefault();
            data = $form.serialize();

            let check = assay.email($email);

            if ( check.code === 0 ) {
                $label.text('Введите e-mail');
                $email.parent().addClass('_error');
            } else if ( check.code === 2 ) {
                $label.text(check.text);
                $email.parent().addClass('_error');
            } else {
                $label.text('');
                $email.parent().removeClass('_error');
                ajax().then(_ => {
                    $email.val('');
                });
            }
        });

    }


    /**
     * Review
     */
    comment($form) {
        let it = this,
            type = $form.attr('method'),
            url = $form.attr('action'),
            $email = $form.find('input[type=email]'),
            $name = $form.find('input[name=author]'),
            $phone = $form.find('input[name=phone]'),
            $text = $form.find('textarea'),
            $res = $form.find('.feedback-form__res'),
            $rate = $form.find('.el-comment__rate'),
            $rateStar = $rate.find('svg'),
            $rateCountHover = 0,
            $rateCount = 0,
            $rateInput = $form.find('input[name="rating"]'),
            $agreement = $form.find('input[name="agreement"]'),
            $submit = $form.find('button[type=submit]');


        $form[0].reset();
        $submit.attr("disabled", "");

        $form.submit(function (event) {
            event.preventDefault();
        });

        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.attributeName === "class") {
                    let rateCount = $(mutation.target)[0].getAttribute('comment_rate_val');
                        $rateInput.val(rateCount);
                        it.checkCommentValidation($form);
                }
            });
        });

        observer.observe($rate[0],{ // заменяем document.body на нужный нам элемент
            attributes: true
        });

        let validator = $form.validate({
            errorElement: "label",
            focusInvalid: false,
            highlight: function (element) {
                $(element).addClass("error");
                $(element).parent().addClass("has-error").removeClass("valid");
                $submit.attr("disabled", "");
            },
            unhighlight: function (element) {
                $(element).removeClass("error");
                $(element).parent().addClass("valid").removeClass("has-error");
                it.checkCommentValidation($form);
            },
            submitHandler: function () {
                it.handleCommentSubmit($form);
            },
            onkeyup: function (element, event) {
                if (event.which === 9 && this.elementValue(element) === "") {
                    return;
                } else {
                    this.element(element);
                }
            },
            rules: {
                "author": {required: true},
                "email": {required: true, email: true},
                "phone": {required: true, number: true, minlength: 8},
                "text": {required: true, minlength: 20},
                "rating": {required: true},
                "agreement": {required: true},
            },
            messages: it.errorMessages,
        });
    }

    checkCommentValidation($form) {
        const $submit = $form.find('button[type=submit]');

        if($form.validate().checkForm()) {
            $submit.removeAttr("disabled");
        } else {
            $submit.attr("disabled", "");
        }
    }

    handleCommentSubmit($form) {
        let it = this,
            type = $form.attr('method'),
            url = $form.attr('action'),
            data = new FormData($form[0]);

        it.$comment_submit.addClass("loading")
            .attr("disabled", "")
            .find("p")
            .html("Отзыв отправляется");

        setTimeout(() => {
            $.ajax({
                url,
                type,
                data,
                processData: false,
                contentType: false,
                success: data => {
                    if (typeof data === 'string') {
                        data = JSON.parse(data);
                    }

                    if ( data.success ) {
                        modal.open('commented');
                        $form.find("textarea").val("");
                        $form.find("input").val("");
                    } else {
                        tooltip.show('Ошибка. Информация в консоли ', 0);
                        console.log(data);
                    }
                },
                error: err => {
                    tooltip.show('Ошибка. Информация в консоли ', 0);
                    console.log(err);
                },
                complete: data => {
                    it.$comment_submit.removeClass("loading")
                        .removeAttr("disabled")
                        .find("p")
                        .html("Оставить отзыв");
                }
            });
        }, 1500)
    }
}
