'use strict';

/*
 Данный класс позволяет выполнять проверку заполнения полей формы.
 */

export default class Assay {
    constructor () {
        let it = this;

        /*
         Коды ошибок:
         0 - поле пустое
         1 - выполнены все условия заполнения
         2 - значение не соответствует ожиданию
         3 - слишком мало символов
         4 - слишком много символов
         */

        it.options = {
            email: {
                require: true,
                name: 'E-mail'
            },
            text: {
                require: false,
                min: 2,
                max: 255
            },
            phone: {
                require: true,
                min: 10,
                max: 11,
                name: 'Телефон'
            }
        };

        it.pattern = {
            email: /[\S]+@[\S]+\.[\S]+/i,
            fullname: /^[А-Яа-яA-Za-z\-]{2,}\s[А-Яа-яA-Za-z\-]{2,}\s*[А-Яа-яA-Za-z\-]*$/iu
        };
    }


    /**
     * Listen Input
     * @param $input, options
     */
    listen ($input, options) {
        let it = this;
        options = options || it.options.email;
        let require = options.require || it.options.email.require;
        let type = options.type;

        switch (type) {
            case 'fullname':
                $input.on('change keyup input click', function () {
                    let value = $input.val();

                    let check = it.pattern.fullname.test(value);
                    if (check) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;

            case 'email':
                $input.on('change keyup input click', function () {

                    let check = it.email($input);

                    if (check.status) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;

            case 'password':
                $input.on('change keyup input click', function () {
                    let value = $input.val();

                    let check = it.text(value, {
                        require: require,
                        min: 4,
                        max: 20
                    });

                    if (check.status) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;

            case 'phone':
                $input.on('change keyup input click', function () {
                    if (this.value.match(/[^0-9]/g)) {
                        this.value = this.value.replace(/[^0-9]/g, '');
                    }

                    let value = $input.val();
                    let check = it.text(value, {
                        require: require,
                        min: 10,
                        max: 11
                    });

                    if (check.status) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;

            case 'numeral':
                $input.on('change keyup input click', function () {
                    if (this.value.match(/[^0-9]/g)) {
                        this.value = this.value.replace(/[^0-9]/g, '');
                    }

                    let value = $input.val();
                    let check = it.text(value, {
                        require: require,
                        min: 1,
                        max: 10000
                    });

                    if (check.status) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;

            case 'text':
                $input.on('change keyup input click', function () {
                    let value = $input.val();
                    let check = it.text(value, {
                        require: require
                    });

                    if (check.status) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;

            default:
                $input.on('change keyup input click', function () {
                    let value = $input.val();
                    let check = it.text(value);

                    if (check.status) {
                        $input.addClass('_filled');
                    } else {
                        $input.removeClass('_filled');
                    }
                });
                break;
        }
    }


    /**
     * E-mail assay
     * @param input, options
     */
    email (input, options) {
        let it = this;
        options = options || it.options.email;
        let email = input ? input.val() : options.value;
        let require = options.require || it.options.email.require;
        let name = options.name || it.options.email.name;

        let answer = {
            value: email
        };

        if ( email === '' ) {
            answer.text = 'Поле '+ name +' не заполнено';
            answer.status = !require;
            answer.code = 0;
        } else if ( ! it.pattern.email.test(email) ) {
            answer.text = 'Введен некорректный '+ name;
            answer.status = !require;
            answer.code = 2;
        } else {
            answer.text = 'Поле '+ name +' заполнено верно';
            answer.status = true;
            answer.code = 1;
        }

        return answer;
    }


    /**
     * Text assay
     * @param input, options
     */
    text (input, options) {
        let it = this;
        options = options || it.options.text;
        let text = typeof input === 'string' || typeof input === 'number' ? input : input.val();
        let require = options.require || it.options.text.require;
        let min = options.min || it.options.text.min;
        let max = options.max || it.options.text.max;
        let name = options.name || input.attr('name');

        let answer = {
            value: text
        };

        if ( ! text.length > 0 ) {
            answer.text = 'Поле '+ name +' не заполнено';
            answer.status = !require;
            answer.code = 0;
        } else if ( text.length < min ) {
            answer.text = 'Поле '+ name +': слишком мало символов (От '+min+' до '+ max +')';
            answer.status = !require;
            answer.code = 3;
        } else if ( text.length > max ) {
            answer.text = 'Поле '+ name +': слишком много символов (От '+min+' до '+ max +')';
            answer.status = !require;
            answer.code = 4;
        } else {
            answer.text = 'Поле '+ name +' заполнено верно';
            answer.status = true;
            answer.code = 1;
        }
        return answer;
    }


    /**
     * Phone assay
     * @param input, options
     */
    phone (input, options) {
        let it = this;
        options = options || it.options.phone;
        let text = typeof input === 'string' || typeof input === 'number' ? input : input.val();
        let require = options.require || it.options.phone.require;
        let min = options.min || it.options.phone.min;
        let max = options.max || it.options.phone.max;
        let name = options.name || it.options.phone.name;

        let answer = {
            value: text
        };


        // преобразование к численному виду
        text = text.replace(/\D+/g,"");


        if ( ! text.length > 0 ) {
            answer.text = 'Поле '+ name +' не заполнено';
            answer.status = !require;
            answer.code = 0;
        } else if ( text.length < min ) {
            answer.text = 'Поле '+ name +': слишком мало символов (От '+min+' до '+ max +')';
            answer.status = !require;
            answer.code = 3;
        } else if ( text.length > max ) {
            answer.text = 'Поле '+ name +': слишком много символов (От '+min+' до '+ max +')';
            answer.status = !require;
            answer.code = 4;
        } else if (text[0] == 8 && text.length === 10) {
            answer.text = 'Не хватает одного символа в поле ' + name;
            answer.status = !require;
            answer.code = 2;
        } else {
            answer.phone = remaster(text);
            answer.format = it.formatPhoneNumber(remaster(text));
            answer.text = 'Поле '+ name +' заполнено верно';
            answer.status = true;
            answer.code = 1;
        }
        return answer;

        function remaster(text) {
            text += '';

            // если символов десять, значит пользователь не ввел восьмерку и ее надо добавить
            if ( text.length === 10 ) {
                text = '8' + text;
            } else if ( text.length === 11 ) {
                // если первый символ 7 - заменяем на 8
                if ( text.substring(0,1) === '7' ) {
                    text = text.replace(/7/,'8');
                }
            } else {
                console.log('не хватает символа');
            }

            return text;
        }
    }


    /**
     * Format Phone Number
     */
    formatPhoneNumber (str) {
        var res = '';
        str += '';
        for (var i=0; i < str.length; i++) {
            if (0 === i) {
                res += str.charAt(i) + ' (';
            } else if (3 === i) {
                res += str.charAt(i) + ') ';
            } else if (6 === i || 8 === i) {
                res += str.charAt(i) + '-';
            } else {
                res += str[i];
            }
        }
        return res;
    }
}