"use strict";

export default class Select {
    constructor () {
        let it = this;

        it.$selects = $('select');
        it.setInput = 1;
    }

    /**
     * Init
     */
    init ($select) {
        let it = this;

        if ($select) {

            let $selectParent = $select.parent();

            if ( $selectParent.hasClass('el-select') ) {
                it.render($selectParent);
            } else {
                $selectParent.append('<div class="el-select"></div>');
                let $elSelect = $selectParent.find('.el-select');
                $elSelect.append($select);
                it.render($elSelect);
            }

        } else {
            if ( it.$selects.length && !$("select").closest(".feedback-form")) {
                it.$selects.each((i, item) => {
                    it.init($(item));
                });
            }

            $(document).on('click', function (e) {
                let $tgt = $(e.target);

                if (!$tgt.hasClass('.el-select') && !$tgt.closest('.el-select').length) {
                    $('.el-select').removeClass('_active');
                }
            });
        }
    }


    /**
     * Init Element Select
     */
    render ($elSelect) {
        let it = this;
        let $select = $elSelect.find('select');
        let $options = $select.find('option');
        let title = $select.data('title');
        let wrap;

        if (it.setInput) {
            wrap = `
                <div class="el-select__wrap">
                    <div class="el-select__title">${title}</div>
                    <span class="el-select__arrow"></span>
                </div>
            `;
        } else {
            wrap = `
                <div class="el-select__wrap">
                    <div class="el-select__input">
                        <span>${title}</span>
                    </div>
                    <span class="el-select__arrow"></span>
                </div>
            `;
        }

        let $list = $('<div class="el-select__list baron" />')
            .append(`
                <div class="el-select__list-wrap baron__scroller">
                    <ul class="el-select__list-ul"></ul>
                </div>
                <div class="el-select__list-track baron__track">
                    <span class="el-select__list-bar baron__bar"></span>
                </div>
            `);

        $options.each((i, item) => {
            let $option = $(item);

            if ( $option.attr('value') !== 'false' ) {
                let $li = $(`<li class="el-select__list-li" data-value="${$option.attr('value')}" />`).text($option.text());

                $list.find('ul').append($li);
            }
        });

        $(wrap).appendTo($elSelect);
        $list.appendTo($elSelect);

        it.process($elSelect).addClass('_inited');
    }



    process ($elSelect) {
        let it = this;
        let $input = $elSelect.find('.el-select__input input');
        let $arrow = $elSelect.find('.el-select__arrow');
        let $title = $elSelect.find('.el-select__title');
        let $list = $elSelect.find('.el-select__list');
        let $wrap = $elSelect.find('.el-select__list-wrap');
        let $bar = $elSelect.find('.el-select__list-bar');
        let $li = $elSelect.find('.el-select__list-li');
        let $select = $elSelect.find('select');

        $input.focus(function () {
            $elSelect.addClass('_active');
        });

        $input.blur(function () {
            setTimeout(() => {
                $elSelect.removeClass('_active');
            }, 100);
        });

        $arrow.on('click', function () {
            if ( $elSelect.hasClass('_active') ) {
                $elSelect.removeClass('_active');
            } else {
                $('.el-select').removeClass('_active');
                $elSelect.addClass('_active');
            }
        });
        $title.on('click', function () {
            if ( $elSelect.hasClass('_active') ) {
                $elSelect.removeClass('_active');
            } else {
                $('.el-select').removeClass('_active');
                $elSelect.addClass('_active');
            }
        });

        $li.on('click', function () {
            let text = $(this).text();
            let value = $(this).attr('data-value');

            $input.val(text);
            $title.text(text);
            $li.removeClass('_active');
            $(this).addClass('_active');
            $elSelect.removeClass('_active');

            $select.find('option[value="'+value+'"]').prop('selected', true);
            $select.trigger('change');
        });

        $input.on('input', function () {
            let value = $input.val();

            $li.each((i, item) => {
                if ( ~$(item).text().toLowerCase().indexOf(value.toLowerCase()) ) {
                    $(item).css('display', 'block');
                } else {
                    $(item).css('display', 'none');
                }
            });
        });

        // кастомный скролл
        baron({
            root: $list,
            scroller: $wrap,
            bar: $bar,
            scrollingCls: '_scrolling',
            draggingCls: '_dragging',
            direction: 'v'
        });

        return $elSelect;
    }
}