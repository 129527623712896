"use strict";

export default class Search {
    constructor () {
        let it = this;

        it.$input = $('#main-search-input');
        it.$triggers = $('.searching__trigger');
        it.$results = $('.searching__results');
        it.$noResults = $('.searching__noresults');
        it.$noResultsGlobal = $('.searching__noresults-global');


        it.search = false;
        it.timeout = setTimeout(() => {}, 10);
        it.delay = 300;

        it.inputFocus = false;
    }


    /**
     * Init
     */
    init () {
        let it = this;

        it.$input.val("");

        it.$input.on('input', function () {
            const query = it.$input.val().trim();

            if(query.length >= 3) {
                clearTimeout(it.timeout);

                it.timeout = setTimeout(() => {
                    it.sendRequest(query);
                }, it.delay);

            }

            if(query.length >= 2 ){
              document.addEventListener( 'keypress', event => {
                if( event.key === 'Enter' ) it.sendRequest(query);
              });
            }
            
        });


        // кастомный скролл
        if ( device.desktop() ) {
            baron({
                root: $('.searching__content'),
                scroller: $('.searching__results'),
                bar: $('.searching__bar'),
                scrollingCls: '_scrolling',
                draggingCls: '_dragging',
                direction: 'v'
            });
        }


        if (device.mobile()) {
            it.$input.focus(function () {
                it.inputFocus = true;
            });

            $('.searching__results').on('scroll', function () {
                if (it.inputFocus) {
                    it.$input.blur();
                    it.inputFocus = false;
                }
            })
        }


        it.$triggers.on('click', function () {
            if($(".searching").hasClass("no_results") || $(".searching").hasClass("waiting_state")) return;

            let $trigger = $(this);
            let category = $trigger.data('category');
            let $resItems = it.$results.find('.searching__item');

            if ($trigger.hasClass('active')) return false;

            it.$triggers.removeClass('_active');
            $trigger.addClass('_active');

            if (category === 'all') {
                $resItems.show();

                it.$results.show();
                it.$noResults.hide();
            } else {
                let $catItems = it.$results.find('.searching__item[data-category='+category+']');

                if ($catItems.length) {
                    $resItems.hide();
                    $catItems.show();

                    it.$results.show();
                    it.$noResults.hide();
                } else {
                    it.$results.hide();
                    it.$noResults.show();
                }
            }
        });
    }

    sendRequest(query) {
        let it = this;

        // $("#main-search-input").prop("disabled", true);

        const url = "/search/?query=" + encodeURIComponent(query);

        $.ajax({
            method: "GET",
            url: url,
            contentType: "application/json",
            dataType: "json",
        })
        .done(function(data) {
            if(typeof data === "string") {
                data = JSON.parse(data);
            }
            if(data.success == true) {
                it.addResults(data.data);
            }
        })
        .always(function(data) {
            if(typeof data === "string") {
                data = JSON.parse(data);
            }
            console.log(data);
            $("#main-search-input").removeAttr("disabled");
        })
    }

    addResults(data) {
        let it = this;
        console.log(data);

        $(".searching").addClass('no-scrollbar');
        $(".searching__item").remove();

        let no_results = true;

        for (var prop in data) {
            if(data[prop].length) {
                data[prop].forEach((item,index) => {
                    it.addSearchItem(item, prop);
                });
                no_results = false;
            }
        }

        $(".searching").removeClass("waiting_state");

        if(no_results) {
            $(".searching").addClass("no_results");
        } else {
            $(".searching").removeClass("no_results");
        }

        setTimeout(() => {
            it.setScrollbar();
        }, 500)

        $(it.$triggers[0]).click();
    }

    setScrollbar() {
        console.log($(".searching__results-inner").height(), $(".searching__results").height())

        if($(".searching__results-inner").height() <= $(".searching__results").height()) {
            $(".searching").addClass('no-scrollbar');
        } else {
            $(".searching").removeClass('no-scrollbar');
        }
    }

    addSearchItem(item, type) {
        let preview_img = item.absolute_preview_path || "";
        const name = item.name || "";

        let category,
            result_type,
            isDefaultImageClass = '';

        switch(type) {
            case "advices": 
                category = "Советы";
                result_type = "advice";
                preview_img = item.preview_image ? `/storage/${item.preview_image}` : '/img/advice.svg';
                isDefaultImageClass = !item.preview_image ? 'default-image' : '';
                break;
            case "products": 
                category = "Каталог";
                result_type = "product";
                preview_img = `/storage/${item.preview_image}`;
                break;
            case "promotions": 
                category = "Новости";
                result_type = "promotion";
                preview_img = `/storage/${item.preview_image}`;
                break;
            case "recipes": 
                category = "Рецепты"; 
                result_type = "recipe";
                break;
            default: 
                category = ""; 
                result_type = "";
                break;
        }

        let html;

        if(item.full_path && item.full_path.length) {
            const href = item.full_path;

            html =     `<a href="${href}" class="searching__item searching__item-${result_type}" data-category="${type}">
                            <div class="searching__item-img ${isDefaultImageClass}" style='background-image: url("${preview_img}");'></div>
                            <div class="searching__item-category">${category}</div>
                            <div class="searching__item-title">${name}</div>
                        </a>`;

        } else {

            html =     `<div class="searching__item searching__item-${result_type}" data-category="${type}">
                            <div class="searching__item-img ${isDefaultImageClass}" style='background-image: url("${preview_img}");'></div>
                            <div class="searching__item-category">${category}</div>
                            <div class="searching__item-title">${name}</div>
                        </div>`;
        }


        $(".searching__results-inner").append(html);
    }
}
