"use strict";

export default class Tooltip {
    constructor () {
        let it = this;

        it.$wrap = $('.tooltips');
    }


    /**
     * Init
     */
    init () {
        let it = this;
    }


    /**
     * Show
     */
    show (text, status) {
        let it = this;
        let _class;

        if ( status === 0 ) {
            _class = ' _err';
        } else if ( status === 1 ) {
            _class = ' _suc';
        } else {
            _class = '';
        }

        let $tooltip = $(`<div class="tooltips__item${_class}"/>`).html(text);

        if(!device.mobile()) {
            it.$wrap.append($tooltip);

            setTimeout(() => {
                $tooltip.remove();
            }, 5000);
        } else {
            alert("Произошла ошибка")
        }
        
    }
}