export default class ReCaptcha {
  constructor () {

  }

  init() {
    let it = this;

    if($(".g-recaptcha").length) {
      it.loadCaptcha();
    }

    window.initCaptcha = it.initCaptcha;
  }

  // Подключаем капчу
  loadCaptcha() {
    var script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=initCaptcha&render=explicit';
    script.defer = 'defer';
    script.async = 'async';

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  // После загрузки капчи вешаем коллбеки
  initCaptcha() {
    let it = this;

    const testing_key = "6LcE_awUAAAAAPVv1-QtHqjzfAB_Vk3aKESFNQN2";
    const site_key = "6LcE_awUAAAAAPVv1-QtHqjzfAB_Vk3aKESFNQN2";

    let container;

    if($("#feedback-captcha").length) {
      container = document.getElementById("feedback-captcha");

      window.registration_captcha = grecaptcha.render(container, {
        sitekey: site_key,
        callback: handleFeedbackCaptchaSuccess,
        "expired-callback": handleFeedbackCaptchaExpiration,
      })
    }

    if($("#review-captcha").length) {
      container = document.getElementById("review-captcha");

      window.registration_captcha = grecaptcha.render(container, {
        sitekey: site_key,
        callback: handleReviewCaptchaSuccess,
        "expired-callback": handleReviewCaptchaExpiration,
      })
    }


    // Login Captcha
    if($("#login-captcha").length) {
      container = document.getElementById("login-captcha");

      window.registration_captcha = grecaptcha.render(container, {
        sitekey: site_key,
        callback: handleLoginCaptchaSuccess,
        "expired-callback": handleLoginCaptchaExpiration,
      })
    }
  }
}
