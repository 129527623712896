"use strict";

// import 'babel-polyfill'

/* Components */
import Select from './modules/Select';
import Modal from './modules/modal';
import Search from './modules/search';
import MobileOS from './helpers/mobile-os';
import Paginator from './modules/paginator';
import ReCaptcha from './modules/recaptcha';
import MainForms from './modules/main-forms';
import RatingComment from './modules/rating-comments';


// Константы
const select = new Select();
const modal = new Modal();
const mobileOS = new MobileOS();
const paginator = new Paginator();
const mainForms = new MainForms();
const search = new Search();
const recaptcha = new ReCaptcha();
const ratingComment = new RatingComment();

/**
 * Клик по документу (нужен для отлова общих кликов по элементам)
 */
$(document).on('click', function (e) {
    let $target = $(e.target);

    // блокировка клика по пустым ссылкам
    if ($target.attr('href') === '#' || $target.closest('a').attr('href') === '#') {
        e.preventDefault();
    }
    // проверка на модальное окно
    else if (/^(\/popup-).+$/.test($target.attr('href')) || /^(\/popup-).+$/.test($target.closest('a').attr('href'))) {
        e.preventDefault();
        let href = $target.attr('href') || $target.closest('a').attr('href');
        modal.open(href.substring(7));
    }
    // закрытие модальных окон по overlay
    else if ($target.hasClass('overlay') || $target.hasClass('overlay__inner')) {
        modal.close();
    }
});

// setCookie("laravel_session", "eyJpdiI6IkhSRTF2bWlQb2FmM0QxMlhFWWpcL3ZBPT0iLCJ2YWx1ZSI6Ik1NNWpieGNLdDBiSExYOFVlR29hTlhkZm94NnBYdUZnQnh2eUZoRUVETDlNR3hJR1NZak1mQ1hKeWFVQTdcL1lwRStiT0V4WmRpUEhKbjdVM1lmbDU4Zz09IiwibWFjIjoiYTE4ZDcyYTVlYzBlZDMxZDU0ZDlhMmYyYzg3ZTZjM2JkZTM3M2E5MDZlOWJjOWZlZWRjNTUwMTdhZDRhMzc0MSJ9", 365);

jQuery.validator.addMethod("email", function (value, element) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);
});

jQuery.validator.addMethod("cyrillic", function (value, element) {
    var regex = /^[а-яА-яёЁ ]+$/;
    return regex.test(value);
});


/**
 * Страница загружена
 */
$(document).ready(function(e){
    // инициализация модулей
    select.init();
    modal.init();

    setTimeout(() => {
      search.init();
      recaptcha.init();
      mainForms.init();
    }, 3000);


    // Mobile Menu
    $('.main-menu__column').each((i, item) => {
        let $column = $(item),
            $title = $column.find('.main-menu__title'),
            $trigger = $column.find('.main-menu__title-trigger'),
            $list = $column.find('.main-menu__list');

        $trigger.on('click', function () {
            if ($title.hasClass('_active')) {
                $title.removeClass('_active');
                $list.slideUp(300);
            } else {
                $title.addClass('_active');
                $list.slideDown(300);
            }
        });
    });


    // активируем пагинатор
    if ($('.el-paginator').length) {
        paginator.init();
    }

    // активируем функции догрузки комментариев в рецепте
    if ($('.raiting__review__list').length) {
        ratingComment.init();
    }


    // Кнопка вверх
    if ($('.el-paginator__upper').length) {
        $('.el-paginator__upper').on('click', function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1000)
        });
    }


    if (typeof USER !== "undefined") {
        let $count_wrap = $(".header__recipes-count span");
        if (USER.recipes.length && typeof USER.recipes == "string") {
            USER.recipes = JSON.parse(USER.recipes);
            USER.recipes = Object.values(USER.recipes);
        }

        if (USER.recipes.length) {
            USER.recipes = [...new Set(USER.recipes)];
            $count_wrap.html(USER.recipes.length);
        }
    }


    // Input file
    $('.form__file').each((i, item) => {
        let $wrap = $(item);
        let $link = $wrap.find('a');
        let $input = $wrap.find('input');
        let $form = $input.closest("form");
        let accepted_types = $input.attr("accept").split(",");
        accepted_types.forEach((type, index) => {
            accepted_types[index] = type.split("/")[1];
        })

        $link.on('click', function () {
            $input.click();
        });

        $input.on('change', function () {
            let file = $input[0].files[0];

            // если файл уже был добавлен - удаляем его html
            if ($wrap.find('.form__file-item').length) {
                $wrap.find('.form__file-item').remove();
            }

            let file_accepted = false;

            var filename = $input.val();
            var extension = filename.replace(/^.*\./, '');

            console.log(extension);

            accepted_types.forEach((type) => {
                console.log(type)
                if (type == extension) {
                    file_accepted = true;
                }
            })

            if (file_accepted) {
                $wrap.find('.form__file-error').remove();
                // Событие обрабатывается скриптом валидации формы
                $form.trigger("file-success");
                // добавляем новый html
                $wrap.append(`
                    <div class="form__file-item">
                        <span class="form__file-item-name">${file.name}</span>
                        <span class="form__file-item-delete">
                            <svg><use xlink:href="#svg-x"></use></svg>
                        </span>
                        <span class="form__file-item-loader">
                            <img src="/img/svg/refresh.svg" alt="">
                        </span>
                    </div>
                `);
            } else {
                // Событие обрабатывается скриптом валидации формы
                $form.trigger("file-error");
                // Добавляем error msg
                $wrap.find('.form__file-error').remove();
                $wrap.append(`
                    <div class="form__file-error">
                        <span>Неподдерживаемый тип файла</span>
                        <svg><use xlink:href="#svg-error-icon"></use></svg>
                    </div>
                `);
            }

            // назначаем события (пока только удаление, но нутром чую, что клиенту будет мало такого функционала)
            $wrap.find('.form__file-item-delete').on('click', function () {
                $wrap.find('.form__file-item').remove();
                $input[0].value = '';
            });
        });
    });


    // Выводим попап с предложеним приложения
    /*const currentOS = mobileOS.get();

    if (getCookie('showAppLink') !== 'android') {
        if (currentOS === 'Android') {
            modal.open('mobile-android');
            setCookie('showAppLink', 'android', 1);

            $('[data-modal="mobile-android"] ._close-modal').click(function () {
                modal.close();
            })
        }
    }

    if (getCookie('showAppLink') !== 'ios') {
        if (currentOS === 'iOS') {
            modal.open('mobile-ios');
            setCookie('showAppLink', 'ios', 1);

            $('[data-modal="mobile-ios"] ._close-modal').click(function () {
                modal.close();
            })
        }
    }*/

    // Модалка Горячая линия
    // if (getCookie('showed-info') === null) {
    //     modal.open('info-popup');
    //     setCookie('showed-info', 'hide', 365);
    // }

    $('[js-popup-cookies-btn]').on('click', function () {
        $('.popup-cookies').addClass('hide');
        setCookie('popup-cookies', 'hide', 365);
    });


});

window.developers = function () {
    console.log('Studio: Grapheme');
    console.log('Frontend: Denis Bookreev');
    console.log('Backend: Alexandr Beloborodov');
};

document.addEventListener('DOMContentLoaded', function() {
  const images = document.querySelectorAll('.lazyload');
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.getAttribute('data-src');
          img.removeAttribute('loading');
          observer.unobserve(img);
        }
      });
    }, {
      rootMargin: '0px',
      threshold: 0.1 // Загружать изображение, когда оно становится видимым на 10% от края экрана
    });

    images.forEach(img => {
      observer.observe(img);
    });
  } else {
    images.forEach(img => {
      img.src = img.getAttribute('data-src');
      img.removeAttribute('loading');
    });
  }
});
