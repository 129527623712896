'use strict';

/* Vendor */




/* Modul */

export default class Modal {
    constructor () {
        let it = this;

        it.$body = $('.body');
        it.$overlay = $('.overlay');
        it.$closeLink = $('._close-modal');

        it.animate = 0;
        it.overlayed = 0;
        it.scrollPosition = 0;
    }


    /**
     * Open Modal
     */
    init ($modal) {
        let it = this;

        if ($modal) {
            $modal.find('._close-modal').on('click', function (e) {
                it.close();
            });
        } else {
            it.$closeLink.on('click', function (e) {
                it.close();
            });
        }

        $('._close-modal').click(function() {
            console.log('ok');
            it.close();
        })
    }


    /**
     * Open Modal
     */
    open (name) {
        let it = this;
        let $modal =  $(`.modal[data-modal=${name}]`);
        it.scrollPosition = $(window).scrollTop();

        it.close();

        if ( !it.overlayed ) {
            it.openOverlay();
        }

        if (name === 'comment' && $('.recipe__header-title').length > 0) {
            let recipeId = $('main.recipe').attr('data-recipe-id');
            let recipeName = $('.recipe__header-title').html();
            $modal.find('.modal__recipe-name')
                .html(recipeName);
            $modal.find('input[name="recipe_name"]')
                .val(recipeName);
            $modal.find('input[name="recipe_id"]')
                .val(recipeId);
        }

        $modal.show();

        if (name = 'search') {
            $('#main-search-input').focus();
        }
    }


    /**
     * Close Modal
     */
    close (modal) {
        let it = this;


        if ( modal ) {
            if ( typeof modal === 'string' || typeof modal === 'number' ) {
                $('.modal_' + modal).css('display', 'none');
            }
        } else {
            $('.modal').hide();
            it.closeOverlay();
        }
    }


    /**
     * Open Overlay
     */
    openOverlay () {
        let it = this;

        it.$overlay.addClass('_show');
        it.$body.addClass('_overlayed');
        $('body').addClass('_overlayed').css({'top': -(it.scrollPosition)});
    }


    /**
     * Close Overlay
     */
    closeOverlay () {
        let it = this;

        it.$overlay.removeClass('_show');
        it.$body.removeClass('_overlayed');
        $('body').removeClass('_overlayed').attr('style', '');
        $(window).scrollTop(it.scrollPosition);
    }


    /**
     * Render Modal
     */
    render ($content) {
        let it = this;

        let $modal = $(`<div class="modal _car-photo" data-modal="car-photo-${$content.attr('data-id')}" />`).html(`
            <div class="modal__inner">
                <div class="modal__header">
                    <div class="modal__title">Добавить фотографии</div>
                </div>
            </div>

            <div class="modal__body">

                <a class="modal__close _close-modal">
                    <svg>
                        <use xlink:href="#svg-x"></use>
                    </svg>
                </a>
               
            </div>
        `);

        $modal.find('.modal__body').append($content);
        it.init($modal);

        return $modal;
    }
}