// "use strict";

import Tooltip from '../modules/tooltip';
const tooltip = new Tooltip();

import Modal from '../modules/modal';
const modal = new Modal();

import Cookie from '../helpers/cookie';
const cookie = new Cookie();

export default class Authorization {

  constructor () {
    let it = this;

    if(!$(".authorization__form").length) return;

    it.$form = $(".authorization__form");
    it.$submit_btn = it.$form.find("button[type=submit]");

    it.errorMessages = {
      "name": "Введите имя",
      "email": {
          required: "Введите E-mail/телефон",
          email: "Некорректный E-mail",
      }
    }

    it.initForm();
  }


  initForm() {
    let it = this;

    it.$form[0].reset();
    it.$submit_btn.attr("disabled", "");

    it.$form.submit(function(event) {
      event.preventDefault();
    });

    let validator = it.$form.validate({
        errorElement: "label",
        focusInvalid: false,
        highlight: function(element) {
          $(element).addClass("error");
          $(element).parent().addClass("has-error").removeClass("valid");
          it.$submit_btn.attr("disabled", "");
        },
        unhighlight: function(element) {
          $(element).removeClass("error");
          $(element).parent().addClass("valid").removeClass("has-error");
          it.checkFormValidation();
        },
        submitHandler: function() {
          it.handleFormSubmit();
        },
        onkeyup: function( element, event ) {
            if ( event.which === 9 && this.elementValue(element) === "" ) {
                return;
            } else {
                this.element(element);
            }
        },
        rules: {
            "email": {required: true},
            "password": {required: true},
        },
        messages: it.errorMessages,
    });


    window.handleLoginCaptchaSuccess = it.handleLoginCaptchaSuccess.bind(this);
    window.handleLoginCaptchaExpiration = it.handleLoginCaptchaExpiration;
    window.checkLoginValidation = it.checkFormValidation;
  }



  checkFormValidation() {
    let it = this;

    if(it.$form.validate().checkForm() && $("#login-captcha").hasClass("success")) {
      it.$submit_btn.removeAttr("disabled");

    } else {
      it.$submit_btn.attr("disabled", "");
    }
  }

  handleLoginCaptchaSuccess() {
    $("#login-captcha").addClass("success");
    this.checkFormValidation();
  }

  handleLoginCaptchaExpiration() {
    $("#login-captcha").removeClass("success");
    this.checkFormValidation();
  }

  handleFormSubmit() {
    let it = this,
        type = it.$form.attr('method'),
        url = it.$form.attr('action'),
        data = new FormData(it.$form[0]);

    it.$submit_btn.addClass("loading").attr("disabled", "").find("p").html("Подождите");
    $('.authorization__form .err-captcha').hide();

    setTimeout(() => {
        $.ajax({
            url,
            type,
            data,
            processData: false,
            contentType: false,
            success: data => {
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                if(data.success) {
                  $.ajax({
                    type: 'GET',
                    url: 'https://promo.petelinka.ru/',
                    data: { u : data.hash },
                    dataType: 'json',
                    xhrFields: { withCredentials: true }
                  })

                    setTimeout(() => {
                        location.href = "/profile?open-menu=1";
                    }, 500);
                } else {
                    tooltip.show('Ошибка. Информация в консоли ', 0);
                    it.$submit_btn.removeClass("loading").removeAttr("disabled").find("p").html("Авторизоваться");
                }
            },
            error: err => {
                if (err.responseJSON.errors) {

                  if (err.responseJSON.errors['g-recaptcha-response']) {
                    $('.authorization__form .err-captcha').text('Для отправки формы капча обязательна').show();
                  }

                } else {

                  if ( err.responseJSON.d.Data.data.Code ) {
                    if (err.responseJSON.d.Data.data.Code === 'Неверный пароль. Если вы ранее регистрировались в программе лояльности, пожалуйста, восстановите пароль.')
                      err.responseJSON.d.Data.data.Code = 'Неверный пароль. Пожалуйста, воспользуйтесь восстановлением пароля.'

                    $('.authorization__form input[name=email]').parent().find('label').text(err.responseJSON.d.Data.data.Code).show();
                  } else {
                    console.log('Ошибка отправки формы');
                  }

                }
            },
            complete: data => {
              if (typeof grecaptcha !== 'undefined' && typeof window.registration_captcha !== 'undefined') {
                grecaptcha.reset(window.registration_captcha);
              }

              it.$submit_btn.removeClass("loading").removeAttr("disabled").find("p").html("Авторизоваться");
            }
        });
    }, 1500)
  }
}
