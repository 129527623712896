// "use strict";

import Tooltip from '../modules/tooltip';
const tooltip = new Tooltip();

import Modal from '../modules/modal';
const modal = new Modal();

export default class PasswordRecovery {

  constructor () {
    let it = this;

    if(!$(".password-recovery__form").length) return;

    it.$form = $(".password-recovery__form");
    it.$submit_btn = it.$form.find("button[type=submit]");

    it.errorMessages = {
      "email": { 
          required: "Введите E-mail/телефон",
          email: "Некорректный E-mail",
      }
    }

    it.initForm();
  }


  initForm() {
    let it = this;

    it.$form[0].reset();
    it.$submit_btn.attr("disabled", "");

    it.$form.submit(function(event) {
      event.preventDefault();
    });

    let validator = it.$form.validate({
        errorElement: "label",
        focusInvalid: false,
        highlight: function(element) {
          $(element).addClass("error");
          $(element).parent().addClass("has-error").removeClass("valid");
          it.$submit_btn.attr("disabled", "");
        },
        unhighlight: function(element) {
          $(element).removeClass("error");
          $(element).parent().addClass("valid").removeClass("has-error");
          it.checkFormValidation();
        },
        submitHandler: function() {
          it.handleFormSubmit();
        },
        onkeyup: function( element, event ) {
            if ( event.which === 9 && this.elementValue(element) === "" ) {
                return;
            } else {
                this.element(element);
            }
        },
        rules: {
            "email": {required: true},
        },
        messages: it.errorMessages,
    });
  }

  checkFormValidation() {
    let it = this;

    if(it.$form.validate().checkForm()) {
      it.$submit_btn.removeAttr("disabled");
    } else {
      it.$submit_btn.attr("disabled", "");
    }
  }

  handleFormSubmit() {
    let it = this,
        type = it.$form.attr('method'),
        url = it.$form.attr('action'),
        data = new FormData(it.$form[0]);

    it.$submit_btn.addClass("loading").attr("disabled", "").find("p").html("Подождите");

    setTimeout(() => {
        $.ajax({
            url,
            type,
            data,
            processData: false,
            contentType: false,
            success: data => {
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                if(data.d.Data.data.Result === true) {
                  it.$form[0].reset();
                  modal.open("password-resetted");
                }
                else {
                  tooltip.show('Ошибка. Информация в консоли ', 0);
                }
            },
            error: err => {

                // Очкорылые программисты первосайта сделали какую-то хуету, приходится костылями дрочить
                if ( err?.responseJSON?.d?.Data?.data?.Code ) {
                    $('.password-recovery__form input[name=email]').parent().find('label').text(err.responseJSON.d.Data.data.Code).show();
                } else if(err?.responseJSON?.message) {
                    $('.password-recovery__form input[name=email]').parent().find('label').text('Восстановление по адресу электронной почты временно недоступно, попробуйте восстановление пароля через номер телефона').show();
                } else {
                    console.log('Ошибка отправки формы');
                }
                console.log(err);
            },
            complete: data => {
                it.$submit_btn.removeClass("loading").removeAttr("disabled").find("p").html("Отправить запрос");
                console.log(data);
            }
        });
    }, 1500)
  }
}
