"use strict";

export default class Paginator {
    constructor () {
        let it = this;

        it.$wrap = $('.el-paginator');
    }



    /**
     * Init
     */
    init () {
        let it = this;

        it.$more = it.$wrap.find('.el-paginator__more');
        it.$upper = it.$wrap.find('.el-paginator__upper');
        it.$first = it.$wrap.find('.el-paginator__first');
        it.$prev = it.$wrap.find('.el-paginator__prev');
        it.$next = it.$wrap.find('.el-paginator__next');
        it.url = it.$wrap.data('url');
        it.page = 0;

        it.$upper.on('click', function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1000)
        });

        it.$more.on('click', function () {
            // it.ajax();
        });

      it.prevPageEvents();
    }

  prevPageEvents() {
    const it = this;
    const prevQuery = it.$prev[0].search
    if(prevQuery && '?page=0' === prevQuery) it.$prev[0].search = '';

  }



    /**
     * Render Pages btns from Paginator
     */
    renderPages (current, total, $btns) {
        let it = this;

        $btns.html('');

        // если страница всего одна, то показывать кнопушки не надо
        if (total == 1 || total == 0) {
            $btns.css('opacity', 0);
            return false;
        }
        $btns.css('opacity', 1);


        // активная кнопка (всегда по центру)
        let $activePage = $(`<a href="#" class="el-paginator__page _active">${current}</a>`).appendTo($btns);

        if (current === 1) {
            if (total < 6) {
                for (let i = 2; i <= total; i++) {
                    $btns.append(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            } else {
                for (let i = 2; i <= 5; i++) {
                    $btns.append(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            }
        } else if (current === 2) {
            $btns.prepend(`
                <a href="#" class="el-paginator__page">1</a>
            `);

            if (total < 5) {
                for (let i = 3; i <= total; i++) {
                    $btns.append(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            } else {
                for (let i = 3; i <= 5; i++) {
                    $btns.append(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            }
        } else if (current === total) {
            if (total < 5) {
                for (let i = total-1; i > 0; i--) {
                    $btns.prepend(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            } else {
                for (let i = total-1; i > total-5; i--) {
                    $btns.prepend(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            }
        } else if (current === total - 1) {
            $btns.append(`
                <a href="#" class="el-paginator__page">${total}</a>
            `);

            if (total < 5) {
                for (let i = total-2; i > 0; i--) {
                    $btns.prepend(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            } else {
                for (let i = total-2; i > total-5; i--) {
                    $btns.prepend(`
                        <a href="#" class="el-paginator__page">${i}</a>
                    `);
                }
            }
        } else {
            for (let i = 1; i < 3; i++) {
                $btns.prepend(`
                    <a href="#" class="el-paginator__page">${current - i}</a>
                `);
            }
            for (let i = 1; i < 3; i++) {
                $btns.append(`
                    <a href="#" class="el-paginator__page">${current + i}</a>
                `);
            }
        }

        return $btns;
    }



    /**
     * Ajax
     */
    ajax () {
        let it = this;
        let type = 'GET';

        return new Promise((resolve, reject) => {
            $.ajax({
                url: it.url,
                type,
                data: {
                    'per_page' : 9
                },
                success: data => {
                    if (typeof data === 'string') {
                        data = JSON.parse(data);
                    }
                    console.log(data);
                },
                error: err => {}
            });
        });
    }
}
